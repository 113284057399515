.home__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-grow: 1;
    width: 100%;
    box-sizing: border-box;
    gap: 3.75rem;
}

h2 {
    font-family: 'BiroScript', sans-serif;
    text-align: center;
    font-weight: 400;
    margin-block-end: 0;
}

.open__hours {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.open__hours div {
    display: flex;
    flex-direction: column;
    gap: .3rem;
    align-items: center;
}

.open__hours > div > p {
    margin: 0;
}

.menu {
    display: flex;
    gap: 2rem;
}

.menu a {
    text-transform: uppercase;
    text-align: center;
}

.email {
    text-decoration: underline !important;
}

.milles__logo {
    width: min(130%, 450px);
    margin-top: 3rem;
}

@media screen and (max-width: 768px) {

    .milles__logo {
        margin-top: 0;
    }

    .open__hours p {
        font-size: .7rem;
    }

    .open__hours__wrapper {
        padding: 0 2rem;
    }

    .menu {
        flex-direction: column;
    }
}